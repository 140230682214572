import * as React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/sections/layout'
import Seo from '../components/sections/seo'

// import components
import LocHove from '../components/loc/loc-hove'
import LocFlansham from '../components/loc/loc-flansham'

const Loc = ({ data }) => {
  const { strapiIndividualLocation } = data
  const { title = '', SEO = {} } = strapiIndividualLocation

  const { page_title = title, page_description = '' } = SEO

  return (
    <Layout>
      <Seo title={page_title} description={page_description} />
      {title.toLowerCase() === 'hove' ? <LocHove data={strapiIndividualLocation} /> : <LocFlansham data={strapiIndividualLocation} />}
    </Layout>
  )
}

export const query = graphql`
  query locQuery($slug: String = "") {
    strapiIndividualLocation(slug: { eq: $slug }) {
      title
      description
      SEO {
        page_title
        page_description
      }
      main_image {
        url
      }
    }
  }
`

export default Loc
