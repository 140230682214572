import React from 'react'
import { useInView } from 'react-intersection-observer'

import { fontSize } from '../../css/mixins/mixins'
import styled from 'styled-components'

const AnimatedContainer = styled.div`
  position: relative;
  margin-bottom: 40px;

  @media only screen and (min-width: 768px) {
    padding-top: 56.25%;
    overflow: hidden;
  }
`

const AnimatedImage = styled.img`
  margin-bottom: 0;
  opacity: 0;
  transform: translateX(-40px);
  transition:
    1s opacity ease-in-out,
    1s transform ease-in-out;

  &.active {
    opacity: 1;
    transform: translateX(0px);
  }
  @media only screen and (min-width: 768px) {
    position: absolute;
    top: 50%;

    opacity: 0;
    transform: translate(50px, -50%);
    transition:
      1s opacity ease-in-out,
      1s transform ease-in-out;

    &.active {
      opacity: 0.75;
      transform: translate(0px, -50%);
    }
  }
`

const AnimatedText = styled.p`
  background-color: rgba(42, 42, 42, 0.95);
  color: #fff;
  font-weight: 100;
  padding: 25px 15px;
  margin-bottom: 0;

  ${fontSize(16)}

  z-index: 10;

  opacity: 0;
  transform: translateX(-40px);
  transition:
    1s opacity ease-in-out,
    1s transform ease-in-out;

  &.active {
    opacity: 1;
    transform: translateX(0px);
  }

  @media only screen and (min-width: 768px) {
    padding: 20px 35px;

    ${fontSize(20)}

    max-width: 60%;
    position: absolute;

    ${({ dir }) =>
      dir === 'left' &&
      `
    left: 15%;
  `}

    ${({ dir }) =>
      dir === 'right' &&
      `
    right: 10%;
  `}

    top: 50%;

    opacity: 0;
    transform: translate(0px, -100%);
    transition:
      1s opacity ease-in-out,
      1s transform ease-in-out;
    transition-delay: 0.6s;

    &.active {
      opacity: 1;
      transform: translate(0px, -50%);
    }
  }
`

const LocAnimatedSection = ({ text = 'this is a test', image = '../../media/images/home_cta_about.jpg', dir = 'right' }) => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  })
  return (
    <>
      <AnimatedContainer ref={ref}>
        <AnimatedImage className={inView ? ' active' : ''} src={image} alt="Ty Paul Personal Training working out in a garden" />
        {text !== '' && (
          <AnimatedText className={inView ? 'active' : ''} dir={dir}>
            {text}
          </AnimatedText>
        )}
      </AnimatedContainer>
    </>
  )
}

export default LocAnimatedSection
